const imgSrc = [
    "/images/hd-wallpaper-3605547_1280.jpg",
    "/images/aurora-1197753_1280.jpg",
    "/images/beach-1852945_1280.jpg",
    "/images/fantasy-2049567_1280.jpg",
    "/images/nature-wallpaper-3151869_1280.jpg",
    "/images/moon-2762111_1280.jpg",
    "/images/godafoss-1840758_1280.jpg",
    "/images/lone-tree-1934897_1280.jpg",
    "/images/trees-1587301_1280.jpg",
    "/images/hd-wallpaper-729512_1280.jpg",
    "/images/fox-1284512_1280.jpg",
    "/images/alberta-2297204_1280.jpg",
    "/images/hd-wallpaper-3010848_1280.jpg",
    "/images/hd-wallpaper-3698073_1280.jpg",
    "/images/lake-1802337_1280.jpg",
    "/images/stones-2040340_1280.jpg",
    "/images/hintersee-3601004_1280.jpg",
    "/images/mountains-3048299_1280.jpg",
    "/images/beach-832346_1280.jpg",
    "/images/nature-3194001_1280.jpg",
    "/images/beach-2089936_1280.jpg",
    "/images/forest-1950402_1280.jpg",
    "/images/boat-4899802_1280.jpg",
    "/images/train-3758523_1280.jpg",
    "/images/deer-643340_1280.jpg",
    "/images/hd-wallpaper-275950_1280.jpg",
    "/images/beach-1799006_1280.jpg",
    "/images/hd-wallpaper-839590_1280.jpg",
    "/images/boat-1014711_1280.jpg",
    "/images/beach-2413081_1280.jpg",
    "/images/lake-1681485_1280.jpg",
    "/images/indiana-dunes-state-park-1848559_1280.jpg",
    "/images/aurora-1185464_1280.jpg",
    "/images/lighthouse-1872998_1280.jpg",
    "/images/hd-wallpaper-3314275_1280.jpg",
    "/images/blue-flower-2197679_1280.jpg",
    "/images/raindrops-574971_1280.jpg",
]

export default imgSrc;